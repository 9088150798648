import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';

ReactDOM
    .createRoot(document.getElementById('root'))
    .render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );