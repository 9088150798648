import AppHeader from "../appHeader/appHeader";
import Slider from "../appSlider/appSlider";
import AppInfo from "../appInfo/appInfo";
import AppDescription from "../appDescription/appDescription";
import AppContacts from "../appContacts/appContacts";
import AppFooter from "../appFooter/appFooter";
import './app.scss';

const App = () => {

  const info = [
    <p>AVANGARD производитель пресс-форм высокого качества для литья изделий из любых видов пластика.</p>
  ]

  const prod = [
    <p style={{width: '300px'}} id="product">Наша продукция</p>
  ]

  const slides = [
    <img src={process.env.PUBLIC_URL + `img/${'head-img1.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'head-img2.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'head-img3.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'head-img3.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'head-img2.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'head-img1.png'}`} className="slider-img" alt=""/>
  ];

  const fence = [
    <img src={process.env.PUBLIC_URL + `img/${'fence1.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'fence2.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'fence3.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'fence3.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'fence2.png'}`} className="slider-img" alt=""/>,
    <img src={process.env.PUBLIC_URL + `img/${'fence1.png'}`} className="slider-img" alt=""/>
  ];


    return (
      <div className="app">
        <AppHeader/>
        <AppInfo info={info}/>
        <Slider slides={slides}/>
        <AppDescription/>
        <AppInfo info={prod}/>
        <Slider slides={fence}/>
        <AppContacts/>
        <AppFooter/>
      </div>
    )
}

export default App;