import React, { useState } from "react";
import './appHeader.scss';

const AppHeader = () => {
  const [menuActive, setMenuActive] = useState(false);

  const data = [
    {name: "О нас", url: "#about"},
    {name: "Ограждение", url: "#fences"},
    {name: "Пресс-формы", url: "#press"},
    {name: "Наша продукция", url: "#product"},
    {name: "Контакты", url: "#contact"},
  ]

  const menuElem = data.map(({name, url}) => {
    return (
      <li className="app__header__menu-name" key={name}><a href={url}>{name}</a></li>
    )
  })
  

 return (
  <header className={menuActive ? "app__header-box app__header-box-active" : "app__header-box"} onClick={() => setMenuActive(!menuActive)}>
    <div className="app__header">
      <p className="app__header-logo">Avangard</p>
      <nav className={menuActive ? "app__menu app__menu-active" : "app__menu"} onClick={() => setMenuActive(!menuActive)}>
        <ul>
          {menuElem}
        </ul>
      </nav>
      <button className={menuActive ? 'app__header-burger app__header-active-burger' : 'app__header-burger'} onClick={() => setMenuActive(!menuActive)}></button>
    </div>
  </header>
  )
}

export default AppHeader;