import './appContacts.scss';

const AppContacts = () => {

  return (
    <div id='contact' className="contacts">
      <h2 className="contacts-title">Контакты</h2>
      <div className="contacts__box">
        <a href="tel: +7(499)959-12-65" className="contacts__box-phone">+7(499)959-12-65</a>
        <a href="tel: +7(495)504-27-06" className="contacts__box-phone">+7(495)504-27-06</a>
      </div>
    </div>
  )
}

export default AppContacts;