import './appInfo.scss';

const AppInfo = ({info}) => {

  return (
    <div className="info" key={'#'}>
      {info}
    </div>
  )
};

export default AppInfo;