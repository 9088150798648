import './appFooter.scss';

const AppFooter = () => {

  return (
    <div className="footer">
      <p>2024 Copyright © Avangard. Все права защищены.</p>
    </div>
  )
}

export default AppFooter;