import './appDescription.scss';
// import boss from '../resources/img/boss.png';
// import barrier from '../resources/img/barrier.png';
// import press from '../resources/img/press.png';


const AppDescription = () => {

  const description = [
    {title: 'О нас', img: 'boss.png', url: "about", text: 'Компания специализируется на литье пластмассовых изделий, а также производстве и обслуживании литьевых пресс-форм. Наша компания производит полимерную продукцию хозяйственно-бытового, промышленного, социального назначения. Мы выпускаем практичные, надежные изделия для автомобильной и пищевой отрасли, продукцию для строительства. Воплощаем практически любые бизнес-идеи.'},
    {title: 'Ограждения', img: 'barrier.png', url: "fences", text: 'Компания производит готовые изделия, как изготовленные по заранее подготовленным проектам, так и по индивидуальным параметрам заказчика, а также есть возможность их комбинирования. В этом заключается их уникальность. По видам бывают: 500 мм, 1000 мм, 2000 мм. Металлопластиковые ограждения являются одним из ключевых элементов при благоустройстве подведомственных территорий, применяются для нужд муниципальных образований и частных заказчиков.'},
    {title: 'Пресс - формы', img: 'press.png', url: "press", text: 'Мы обладаем большим опытом в проектировании и изготовлении пресс-форм, а также в их обслуживании, проведении ремонтных работ. Это позволяет предлагать нашим клиентам пресс-формы, точно соответствующие не только техническому заданию, но и их поже- ланиям. Все пресс-формы после производства и утверждения образцов проходят испытания, после чего принимаются заказчиком на нашем литьевом производстве.'}
  ]

  const elem = description.map(({title, img, text, url}) => {
		return (
			<div className='description' id={url} key={title}>
        <div className="description__box">
          <p className="description__box-title">{title}</p>
          <div className="description__box-text">{text}</div>
        </div>
            <img src={process.env.PUBLIC_URL + `img/${img}`} className="description-img" alt={title}/>
      </div>
		)
	})


  return (
    <div className='description__wrapper'>
      {elem}
    </div>
  )
};

export default AppDescription;