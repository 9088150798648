import React, { useState, useEffect  } from 'react';
import './appSlider.scss';

const Slider = ({slides}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  let slidesToShow = 3;
  if (width < 1201) {
    slidesToShow = 2;
  }  
  
  if (width < 769) {
    slidesToShow = 1;
  }

  console.log(slides.length)

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - slidesToShow ? 0 : prevIndex + slidesToShow
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - slidesToShow : prevIndex - slidesToShow
    );
  };

  return (
    <div className="slider">
      <button className="slider-button" onClick={prevSlide}></button>
      <div className="slider-window">
        <div
          className="slider-content"
          style={{ transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)` }}
        >
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              {slide}
            </div>
          ))}
        </div>
      </div>
      <button className="slider-button" onClick={nextSlide}></button>
    </div>
  );
};

export default Slider;